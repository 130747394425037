import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../components/app.scss"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../components/CheckoutForm"

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

function Checkout({ data }) {
  const [price, setPrice] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [shipping, setShipping] = useState(5)
  const [discount, setDiscount] = useState(0)

  useEffect(() => {
    const p = getPrice()

    if (+p === 0) {
      window.location.href = "/"
    }

    setPrice(p)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPrice = () => {
    const cart = localStorage.getItem("cupid_cart")
    const disc = localStorage.getItem("discount")

    let combo = false

    if (cart === null) {
      return 0
    } else {
      let cartData = JSON.parse(cart)
      let price = 0

      cartData.forEach(product => {
        const unitPrice = +product.quantity * product.price

        price += unitPrice

        if (product.category === "COMBO") {
          combo = true
          setShipping(0)
        } else if (
          product.category === "SCRAPBOOK" ||
          product.category === "BOTTLE" ||
          product.category === "TEDDY"
        ) {
          setShipping(10)
        } else {
          setShipping(5)
        }
      })

      let less = 0

      if (disc !== null) {
        setDiscount(+disc)
        less = +disc
      } else {
        setDiscount(0)
      }

      const originalPrice = calculateOriginalPrice(cartData, less)

      setSubtotal(price)

      const priceWithTax = combo
        ? (+price - less + 0.0) * 1.0887
        : (+price - less + shipping) * 1.0887

      if (+priceWithTax !== +originalPrice) {
        console.log("Cheated")
      }

      return originalPrice
    }
  }

  const calculateOriginalPrice = (cartData, less) => {
    let prices = {}
    data.allProduct.nodes.forEach(el => {
      prices[el.id] = el.discount_price
    })

    let total = 0
    let combo = false
    let shipCharge = 5

    cartData.forEach(product => {
      if (prices[product.id]) {
        total += +prices[product.id] * +product.quantity
      }
      if (product.category === "COMBO") {
        combo = true
        shipCharge = 0
      } else if (
        product.category === "SCRAPBOOK" ||
        product.category === "BOTTLE" ||
        product.category === "TEDDY"
      ) {
        shipCharge = 10
      } else {
        shipCharge = 5
      }
    })

    return combo
      ? (+total - less + 0.0) * 1.0887
      : (+total - less + +shipCharge) * 1.0887
  }

  return (
    <Elements stripe={stripePromise}>
      <div>
        <SEO title="Checkout" />

        <div className="container">
          <CheckoutForm
            price={price}
            shipping={shipping}
            subtotal={subtotal}
            discount={discount}
          />
        </div>
      </div>
    </Elements>
  )
}

export default Checkout

export const pageQuery = graphql`
  query CheckoutQuery {
    allProduct(sort: { fields: price, order: DESC }) {
      nodes {
        category
        description
        id
        images {
          type
          url
        }
        name
        price
        discount_price
        points
      }
    }
  }
`
