import firebase from "firebase/app"
import "firebase/firestore"

var firebaseConfig = {
  apiKey: "AIzaSyBEvCe9uAksZTdSxjABMlaidMd0y6WVKP8",
  authDomain: "cupidscone1.firebaseapp.com",
  projectId: "cupidscone1",
  storageBucket: "cupidscone1.appspot.com",
  messagingSenderId: "180957905590",
  appId: "1:180957905590:web:a4181190ebd99dccf7dc17",
  measurementId: "G-8ZTR7CN6PE",
}

firebase.initializeApp(firebaseConfig)

//firebase.analytics()

export default firebase
