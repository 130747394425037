import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import axios from "axios"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Link } from "gatsby"
import firebase from "../firebase.config"

const cardElementOption = {
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {},
  },
  hidePostalCode: true,
}

export default function CheckoutForm(props) {
  const [loading, setLoading] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [error, setError] = useState("")
  const [shipping1, setShipping1] = useState("")
  const [shipping2, setShipping2] = useState("")
  const [billing1, setBilling1] = useState("")
  const [billing2, setBilling2] = useState("")
  const [same, setSame] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const elements = useElements()
  const stripe = useStripe()

  const showErrorDialog = message => {
    setLoading(false)
    setShowError(true)
    setErrorMessage(message)
  }
  const showSuccessDialog = () => {
    setLoading(false)
    setShowSuccess(true)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setError("")

    if (!cardComplete) {
      setError("Card information isn't valid")
      return
    }
    if (!stripe || !elements) {
      return
    }
    setLoading(true)

    const userInfo = {
      name: `${e.target.first_name.value} ${e.target.last_name.value}`,
      email: e.target.email.value,
      phone: e.target.phone.value,
      address: {
        country: e.target.country.value,
        line1: e.target.address.value,
        line2: e.target.address2.value || "",
        state: e.target.state.value,
        postal_code: e.target.zip.value,
      },
    }

    const cart = localStorage.getItem("cupid_cart")
    let cartData = []

    if (cart === null) {
      return
    } else {
      cartData = JSON.parse(cart)
    }
    try {
      const {
        data: clientSecret,
      } = await axios.post(
        "https://us-central1-cupidscone1.cloudfunctions.net/payment_intent",
        { amount: props.price.toFixed(2) * 100 }
      )

      const cardElement = elements.getElement(CardElement)

      const paymentMethodRequest = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: userInfo,
      })

      if (paymentMethodRequest.error) {
        showErrorDialog(paymentMethodRequest.error.message)
        return
      }

      const orderInfo = {
        ...userInfo,
        billing1: same ? shipping1 : billing1,
        billing2: same ? shipping2 : billing2 || "",
        products: cartData,
        total_price: props.price.toFixed(2),
        date: new Date(),
        payment_method_id: paymentMethodRequest.paymentMethod.id,
      }

      const confirmedCardPayment = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethodRequest.paymentMethod.id,
        }
      )

      if (confirmedCardPayment.error) {
        setLoading(false)
        showErrorDialog("Failed! Can't proceed this payment now.")
        return
      }
      if (confirmedCardPayment.paymentIntent.status === "canceled") {
        setLoading(false)
        showErrorDialog("Your payment is canceled!")
        return
      }
      if (confirmedCardPayment.paymentIntent.status === "succeeded") {
        const userRef = await firebase
          .firestore()
          .collection("orders")
          .add(orderInfo)

        if (userRef) {
          try {
            const data = await axios.post(
              "https://us-central1-cupidscone1.cloudfunctions.net/send_email",
              {
                order_id: userRef.id,
                customer_name: orderInfo.name,
                customer_email: orderInfo.email,
                subtotal: props.subtotal,
                discount: props.discount,
                tax:
                  (props.subtotal - props.discount + props.shipping) * 0.0887,
                shipping: props.shipping,
                total: props.price,
                cartData: cartData,
              }
            )

            setLoading(false)
            showSuccessDialog()
            document.getElementById("cupid-checkout").reset()
            localStorage.clear()
          } catch (error) {
            setLoading(false)
            showSuccessDialog()
            document.getElementById("cupid-checkout").reset()
            localStorage.clear()
          }

          return
        } else {
          setLoading(false)
          showErrorDialog("Something went wrong")
          return
        }
      } else {
        setLoading(false)
        showErrorDialog("Your payment is failed!")
        return
      }
    } catch (error) {
      setLoading(false)
      console.log(error.response.data)
      showErrorDialog("Something went wrong")
      return
    }
  }

  return (
    <div className="row justify-content-center ">
      <form
        onSubmit={handleSubmit}
        id="cupid-checkout"
        className="col-md-6 payment-card"
      >
        <h1 className="text-center secret">Checkout</h1>
        <p className="text-center">Secured By SSL</p>
        <h5 className="mt-5 text-center mb-3">Personal Information</h5>
        <hr></hr>
        <div className="row">
          <div className="col-md-6 my-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              name="first_name"
              placeholder="John"
              required
            />
          </div>
          <div className="col-md-6 my-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              name="last_name"
              placeholder="Doe"
              required
            />
          </div>
        </div>
        <label htmlFor="card">Enter your card details</label>
        <div className="mb-3 form-control py-3 mt-2">
          <CardElement
            options={cardElementOption}
            onChange={e => {
              if (e.error !== undefined) {
                setError(e.error.message)
              }
              setCardComplete(e.complete)
            }}
          />
        </div>
        {/* <div>{error}</div */}
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            name="email"
            placeholder="name@example.com"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Phone number
          </label>
          <input
            type="phone"
            className="form-control"
            id="exampleFormControlInput1"
            name="phone"
            placeholder="+1 XXX XXXXXXX"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Shipping address
          </label>
          <input
            type="text"
            className="form-control my-2"
            id="exampleFormControlTextarea1"
            name="address"
            placeholder="Address line 1"
            required
            onChange={e => setShipping1(e.target.value)}
            value={shipping1}
          ></input>
          <input
            type="text"
            className="form-control my-2"
            id="exampleFormControlTextarea1"
            name="address2"
            placeholder="Address line 2"
            onChange={e => setShipping2(e.target.value)}
            value={shipping2}
          ></input>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Billing address
          </label>
          <input
            type="text"
            className="form-control my-2"
            id="exampleFormControlTextarea1"
            name="billaddress"
            placeholder="Address line 1"
            value={same ? shipping1 : billing1}
            onChange={e => setBilling1(e.target.value)}
            disabled={same}
            required
          ></input>
          <input
            type="text"
            className="form-control my-2"
            id="exampleFormControlTextarea1"
            name="billaddress2"
            value={same ? shipping2 : billing2}
            onChange={e => setBilling2(e.target.value)}
            placeholder="Address line 2"
            disabled={same}
          ></input>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              checked={same}
              onChange={e => {
                setSame(!same)
              }}
              id="flexCheckDefault"
            />
            <label class="form-check-label" htmlFor="flexCheckDefault">
              Same as shipping address
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Country
            </label>
            <select
              className="form-control"
              id="exampleFormControlInput1"
              name="country"
              placeholder="name@example.com"
              required
            >
              <option value="US">United States</option>
            </select>
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              State
            </label>
            <select
              className="form-control"
              id="exampleFormControlInput1"
              name="state"
              placeholder="name@example.com"
              required
            >
              <option value="">Select a State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Zip
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleFormControlInput1"
              name="zip"
              placeholder=""
              required
            />
          </div>
        </div>

        <div className="text-danger">{error}</div>

        {loading ? (
          <button
            type="submit"
            className="col-md-12 w-100 py-2 btn btn-primary mt-4"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp; Processing
          </button>
        ) : (
          <button
            type="submit"
            className="col-md-12 w-100 py-2 btn btn-primary mt-4"
          >
            Pay ${props.price.toFixed(2)}
          </button>
        )}
      </form>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        backdrop="static"
        centered="true"
        keyboard={false}
      >
        <Modal.Body>
          <div className="col-md-12 text-center mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="150"
              fill="#10c96f"
              class="bi bi-check2-circle"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
            </svg>
          </div>
          <h3 className="text-center mb-5">Your payment has been successful</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100 justify-content-center">
            <Link to="/" className="btn btn-primary col-md-8">
              Back to home
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showError}
        onHide={() => setShowError(false)}
        backdrop="static"
        centered="true"
        keyboard={false}
      >
        <Modal.Body>
          <div className="col-md-12 text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="150"
              fill="#e0a800"
              class="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </div>
          <h3 className="text-center">{errorMessage}</h3>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => setShowError(false)}
            className="btn btn-primary"
          >
            Understood
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
